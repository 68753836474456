<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-center">
          <b-tabs
            nav-class="mb-3"
            pills
          >
            <b-tab
              class="text-left"
              active
              title="Trenches"
            >
              <app-collapse
                accordion
                type="margin"
              />
              <!-- search input -->
              <div class="custom-search d-flex justify-content-start">
                <b-form-group
                  label=""
                  label-size="sm"
                  class="form-inline"
                >
                  <b-form-input
                    v-model="trenches.search"
                    class="d-inline-block mr-1"
                    placeholder="Search trenches"
                    type="text"
                  />
                  <b-button
                    v-b-modal.tapplicationModal
                    variant="primary"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add Trenches
                  </b-button>
                </b-form-group>
              </div>

              <!-- table -->
              <b-overlay
                :show="$apollo.loading"
                spinner-variant="primary"
              >
                <vue-good-table
                  :columns="trenches.columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:trenches.pageLength
                  }"
                  :rows="trenchesRows"
                  :search-options="{
                    enabled: true,
                    externalQuery: trenches.search }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >

                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'action'">
                      <span class="drop-menu">
                        <b-dropdown
                          dropup
                          block
                          no-caret
                          variant="link"
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              class="text-body align-middle"
                              icon="MoreVerticalIcon"
                              size="16"
                            />
                          </template>
                          <b-dropdown-item :to="`/fundings/trench`">
                            <feather-icon
                              class="mr-50"
                              icon="EyeIcon"
                            />
                            <span>Trench Review</span>
                          </b-dropdown-item>
                          <b-dropdown-item :to="`/fundings/evalution`">
                            <feather-icon
                              class="mr-50"
                              icon="UserIcon"
                            />
                            <span> Evaluation</span>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <feather-icon
                              class="mr-50"
                              icon="BoxIcon"
                            />
                            <span> Update progress </span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="trenches.pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="trenches.pageLength"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-overlay>
              <b-modal
                id="tapplicationModal"
                size="lg"
                title="Trenches Form"
              >
                <TrenchesFormBuilder
                  v-model="trenches.applicationForm"
                  :inherited-sections="trenches.applicationForm"
                />
              </b-modal>
            </b-tab>
            <b-tab title="Advisors">
              <!-- search input -->
              <div class="custom-search d-flex justify-content-start">
                <b-form-group
                  label=""
                  label-size="sm"
                  class="form-inline"
                >
                  <b-form-input
                    v-model="advisor.search"
                    class="d-inline-block mr-1"
                    placeholder="Search advisor"
                    type="text"
                  />
                  <b-button
                    v-b-modal.aapplicationModal
                    variant="primary"
                  >
                    <feather-icon icon="PlusIcon" />
                    Add Advisor
                  </b-button>
                </b-form-group>
              </div>

              <!-- table -->
              <vue-good-table
                :columns="advisor.columns"
                :pagination-options="{
                  enabled: true,
                  perPage:advisor.pageLength
                }"
                :rows="advisorRows"
                :search-options="{
                  enabled: true,
                  externalQuery: advisor.search }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        dropup
                        no-caret
                        size="sm"
                        toggle-class="text-decoration-none"
                        variant="link"
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            class="text-body align-middle"
                            icon="MoreVerticalIcon"
                            size="16"
                          />
                        </template>
                        <b-dropdown-item>
                          <feather-icon
                            class="mr-50"
                            icon="EyeIcon"
                          />
                          <span>View Assignment</span>
                        </b-dropdown-item>
                        <b-dropdown-item :to="`/fundings/advisor/assigntrench`">
                          <feather-icon
                            class="mr-50"
                            icon="UserIcon"
                          />
                          <span>Assign Trench</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>

                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Showing 1 to
                      </span>
                      <b-form-select
                        v-model="advisor.pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                      <b-pagination
                        :per-page="advisor.pageLength"
                        :total-rows="props.total"
                        :value="1"
                        align="right"
                        class="mt-1 mb-0"
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
              <b-modal
                id="aapplicationModal"
                size="lg"
                title="Advisor Form"
              >
                <AdvisorFormBuilder
                  v-model="advisor.applicationForm"
                  :inherited-sections="advisor.applicationForm"
                />
              </b-modal>
            </b-tab>
            <b-tab
              class="text-left"
              title="Investors"
            >
              <!-- search input -->
              <div class="custom-search d-flex justify-content-start">
                <b-form-group
                  label=""
                  label-size="sm"
                  class="form-inline"
                >
                  <b-form-input
                    v-model="investor.search"
                    class="d-inline-block mr-1"
                    placeholder="Search investors"
                    type="text"
                  />
                </b-form-group>
              </div>

              <!-- table  -->
              <vue-good-table
                :columns="investor.columns"
                :pagination-options="{
                  enabled: true,
                  perPage:investor.pageLength
                }"
                :rows="investorRows"
                :search-options="{
                  enabled: true,
                  externalQuery: investor.search }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Action -->
                  <span
                    v-if="props.column.field === 'action'"
                  >
                    <b-dropdown
                      dropright
                      size="sm"
                      toggle-class="text-decoration-none"
                      variant="link"
                      class="dropdown-menu-class"
                    >
                      <template
                        v-slot:button-content
                      >
                        <feather-icon
                          class="text-body align-middle"
                          icon="MoreVerticalIcon"
                          size="16"
                        />
                      </template>
                      <b-dropdown-item :to="`/fundings/investor/viewtrenches`">
                        <feather-icon
                          class="mr-50"
                          icon="EyeIcon"
                        />
                        <span>View trenches </span>
                      </b-dropdown-item>
                      <b-dropdown-item :to="`/fundings/investor/fundstransactions`">
                        <feather-icon
                          class="mr-50"
                          icon="UserIcon"
                        />
                        <span>Funds transactions</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </template>
                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Showing 1 to
                      </span>
                      <b-form-select
                        v-model="investor.pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                      <b-pagination
                        :per-page="investor.pageLength"
                        :total-rows="props.total"
                        :value="1"
                        align="right"
                        class="mt-1 mb-0"
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
  BTab,
  BTabs,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { reactive } from '@vue/composition-api'
import { VueGoodTable } from 'vue-good-table'
import TrenchesFormBuilder from '@/views/fundings/fundingManager/components/TrenchesFormBuilder.vue'
import AdvisorFormBuilder from '@/views/fundings/fundingManager/components/AdvisorFormBuilder.vue'
// import InvestorFormBuilder from '@/views/fundings/fundingManager/components/InvestorFormBuilder.vue'
import gql from 'graphql-tag'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    TrenchesFormBuilder,
    AdvisorFormBuilder,
    // InvestorFormBuilder,
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    AppCollapse,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rounds: [
        { title: '' },
      ],
      selectedRound: 0,
      trenchesRows: [],
      advisorRows: [],
      investorRows: [],
    }
  },
  setup() {
    const trenches = reactive(
      {
        selected: {
          id: null,
        },
        search: '',
        applicationForm: null,
        pageLength: 5,
        filter: {
          status: 'Current',
        },
        columns: [
          {
            label: 'Action',
            field: 'action',
            sortable: false,
          },
          {
            label: 'Trench Title',
            field: 'title',
          },
          {
            label: 'Trench Amount',
            field: 'trench_amount',
          },
          {
            label: 'Status',
            field(trenchesRows) {
              if (!trenchesRows.funding_basicinfo) return '-'
              return trenchesRows.funding_basicinfo.status || '-'
            },
          },
          {
            label: 'Equity Allotment',
            field(trenchesRows) {
              if (!trenchesRows.funding_basicinfo) return '-'
              return trenchesRows.funding_basicinfo.equity || '-'
            },
          },
        ],
      },
    )
    const advisor = reactive(
      {
        selected: {
          id: null,
        },
        search: '',
        applicationForm: null,
        pageLength: 5,
        filter: {
          status: 'Current',
        },
        columns: [
          {
            label: 'Action',
            field: 'action',
            sortable: false,
          },
          {
            label: 'Index',
            field: '',
          },
          {
            label: 'Trench Title',
            field: 'trench_number',
          },
          {
            label: 'Designation',
            field: 'title',
          },
        ],
      },
    )
    const investor = reactive({
      selected: {
        id: null,
      },
      search: '',
      applicationForm: null,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Investors Name',
          field: 'trench_number',
        },
        {
          label: 'Funds committed',
          field(investorRows) {
            if (!investorRows.funding_basicinfo) return '-'
            return investorRows.funding_basicinfo.funds || '-'
          },
        },
        {
          label: 'Funds Provided',
          field: 'price',
          formatFn(value) {
            return `₹${value}`
          },
        },
        {
          label: 'Total Equity Committed',
          field: 'capacity',
        },
        {
          label: 'Total Equity Disbursed',
          field(investorRows) {
            if (!investorRows.funding_basicinfo) return '-'
            return investorRows.funding_basicinfo.equity || '-'
          },
        },
      ],
    })
    return {
      trenches,
      advisor,
      investor,
    }
  },
  apollo: {
    trenchesRows: {
      query() {
        return gql`
        {
           funding_basicinfo_by_pk(id: ${this.$route.params.id}) {
            funding_trenchestables {
             trench_amount
             title
             trench_number
           }
          }
        }`
      },
      update: data => data.funding_basicinfo_by_pk.funding_trenchestables,
    },
    advisorRows: {
      query() {
        return gql`
        {
           funding_basicinfo_by_pk(id: ${this.$route.params.id}) {
            funding_trenchestables {
             trench_amount
             title
             trench_number
           }
          }
        }`
      },
      update: data => data.funding_basicinfo_by_pk.funding_trenchestables,
    },
  },
  created() {
    this.$http.get('/events')
      .then(res => {
        this.investorRows = res.data
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.dropdown-menu-class{
  max-height: 100px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(7, 8, 12, 0.4);
    border-radius: 3px;
    &:hover {
      background: rgba(7, 8, 12,.4);
    }
  }
}
</style>
